<template>
  <swiper ref="mySwiper" :options="swiperOptions" class="swiper-ctnr mb-3">
    <swiper-slide v-for="(image, idx) in images" v-bind:key="idx">
      <div class="swiper-zoom-container">
        <img :src="image.url" :alt="image.alt" class="img-swiper">
      </div>
    </swiper-slide>
    <div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
  </swiper>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'

export default {
  name: "SwiperImages",
  props: {
    images: Array,
  },
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  data() {
    return {
      lang: this.$lang,
      swiperOptions: {
          zoom: false,
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          },
          fadeEffect: {
            crossFade: true
          },
          // Some Swiper option/callback...
        }
      }
    },
        mounted()
    {
      //this.swiper.slideTo(3, 1000, false)
    }
  }
</script>

<style scoped>
.img-swiper{
  max-height: 519px;
}

.swiper-ctnr {
  width: 100%;
}
.swiper-pagination >>> .swiper-pagination-bullet {
  opacity: 1;
  border: #d0a3a3 solid 1px;
  background-color: transparent;
}

.swiper-pagination >>> .swiper-pagination-bullet-active {
  background-color: rgb(229, 154, 148);
}
</style>
