<template>
  <div>
    <NavBar></NavBar>
    <Loader v-if="loading"></Loader>
    <div v-else class="container">
      <div  class="row row-cols-md-2 row-cols-sm-1 mt-3">
        <!-- Image -->
        <div class="col">
          <SwiperImages :images="product.images"></SwiperImages>
        </div>
        <!-- title short desc + price + add to cart -->
        <div class="col">
          <h1>{{product.name}}</h1>

          <p>{{product.shortDescription}}</p>

          <div v-html="product.description"></div>
          <!-- alerts in case of errors -->
          <div class="alert alert-danger mt-3" role="alert" v-if="showErrorAlert">
            {{ errorMessage }}
          </div>
          <div class="alert alert-success mt-3" role="alert" v-if="showSuccessAlert">
            {{ successMessage }}
          </div>
          <!-- add to cart -->
          <div class="row mt-3">
            <div class="col text-center">
              <p>{{product.totalPrice.display}}</p>
            </div>
            <div class="col">
              <button @click="onAddToCart" type="button" class="btn btn-success btn-block">Add to Cart</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="product.imagesWorn !== null && product.imagesWorn.length > 0">
        <div class="row">
          <div class="col-6" v-for="(p,idx) in product.imagesWorn" :key="idx">
            <b-img fluid :src="p.url" :alt="p.alt"></b-img>
          </div>
        </div>
      </div>

      <div v-if="product.imagesOther !== null && product.imagesOther.length> 0">
        <br>
        <div class="row">
          <div class="col-6" v-for="(p,idx) in product.imagesOther" :key="idx">
            <b-img fluid :src="p.url" :alt="p.alt"></b-img>
          </div>
        </div>
      </div>
      <br>
      <button @click="onAddToCart" type="button" class="btn btn-success btn-block btn-block">Add to Cart</button>
      <br>
      <!-- Video -->
      <div class="row" v-if="product.videoCloudflareId !== ''">
        <br>
        <div class="col-12 col-md-9">
          <!-- Video -->
          <div class="embed-responsive embed-responsive-16by9"  >
            <iframe
                class="embed-responsive-item"
                :src="'https://iframe.videodelivery.net/'+product.videoCloudflareId+'?autoplay=true&muted=true'"
                style="border: none;"
                height="720"
                width="1280"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowfullscreen="true"
            ></iframe>
          </div>
        </div>
      </div>
      <div class="text-center mt-5">
        <h2 class="bap-title-darker">Contact Me</h2>
        <p>Do you want to ask a question?</p>
        <p>Are you hesitating?</p>
        <p>Do you want to order something different?</p>
        <p>Do you want to order large quantities?</p>
        <p>You want to ask a question ?</p>
        <h2 class="bap-title-darker">By Chat</h2>
        <b-row>
          <b-col class="">
            <b-button variant="info" class="mb-2" @click="openCrisp">
              <i class="bi bi-chat"></i>
              Open Chat
            </b-button>
          </b-col>
        </b-row>
        <br>
        <h2 class="bap-title-darker">With our contact form</h2>
        <router-link :to="{name:constants.ROUTE_NAMES.FEEDBACK}">
          <b-button variant="info" class="mb-2">
            <i class="bi bi-ui-checks"></i>
            Open Contact form
          </b-button>
        </router-link>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import Webservice from "../webservice";
import Loader from "../components/Loader";
import AuthHelper from "../authHelper"
import Consts from "@/consts";
import SwiperImages from "@/components/SwiperImages";

export default {
  name: "ProductDetail",
  components: {SwiperImages, Loader, NavBar,Footer},
  props: {
    productId: String
  },

  data(){
    return {
      videoURL: "",
      errorMessage: "",
      showErrorAlert : false,
      successMessage: "",
      showSuccessAlert : false,
      product: null,
      loading: false,
      constants: Consts,
      titleMeta: "Practical Go Lessons Product",
      descriptionMeta:"Cute Gopher Merchandising",
    }
  },
  metaInfo () {
    return {
      title: this.titleMeta,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.titleMeta },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: this.titleMeta},
        { property: 'og:description', content: this.descriptionMeta },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]
    }
  },
  created() {
    this.loading = true
    Webservice.getProductByID(this.productId).then((res)=> {
      this.product = res.data
      this.titleMeta= this.product.name+" - Practical Go Lessons"
      this.descriptionMeta = this.productId.shortDescription
      AuthHelper.getOrCreateAnonUser().then((user)=> {
        Webservice.tracking({
          action: {
            referrer: document.referrer,
            lang: navigator.language || navigator.userLanguage,
            name: "VISIT_PDP_"+this.productId,
            utm: {
              source: this.$route.query.utm_source,
              medium: this.$route.query.utm_medium,
              campaign: this.$route.query.utm_campaign,
              term: this.$route.query.utm_term,
              content: this.$route.query.utm_content,
            }
          }
        }, user.idToken)
      })
      this.loading = false
    }).catch((err)=>{
      alert(err)
      this.loading = false
    })
  },
  methods: {
    onAddToCart(){
      // get current user & create anonymous user if no firebase user
      AuthHelper.getOrCreateAnonUser().then((user)=> {
        Webservice.addToCart(this.productId,1, user.idToken).then((res)=> {
          if (res.status === 204) {
            // not in stock
            //this.makeToast("Error", "Impossible to add to cart, this item is not in stock anymore", "danger")
            this.errorMessage = "Impossible to add to cart, this item is not in stock anymore"
            this.showErrorAlert = true
            return
          }
          Webservice.tracking({
            action:{
              referrer:document.referrer,
              lang: navigator.language || navigator.userLanguage,
              name:"ADD_TO_CART",
              utm: {
                source: this.$route.query.utm_source,
                medium: this.$route.query.utm_medium,
                campaign: this.$route.query.utm_campaign,
                term: this.$route.query.utm_term,
                content: this.$route.query.utm_content,
              }
            }
          }, user.idToken)
          this.$router.push({name:Consts.ROUTE_NAMES.CART})
        }).catch((err)=> {
          console.error("impossible to add to cart :"+err)
          this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
        })
      }).catch((err)=>{
        // What to do ?
        console.error("error while retrieving the current user:"+err)
        this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
      })
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: true
      })
    },
    openCrisp() {
      window.$crisp.push(['do', 'chat:open'])
    }

  }
}
</script>

<style scoped>

</style>